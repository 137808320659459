@font-face {
  font-family: "Roboto Slab Regular";
  src:
    local("Roboto Slab"),
    url("../fonts/roboto-slab/RobotoSlab-Regular.ttf") format("truetype");
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Slab bold";
  src:
    local("Roboto Slab"),
    url("../fonts/roboto-slab/RobotoSlab-Bold.ttf") format("truetype");
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Regular";
  src:
    local("Roboto"),
    local("Droid Sans"),
    url("../fonts/roboto/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Light";
  src:
    local("Roboto Light"),
    local("Droid Sans"),
    url("../fonts/roboto/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Medium";
  src:
    local("Roboto Medium"),
    local("Droid Sans"),
    url("../fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Bold";
  src:
    local("Roboto Bold"),
    local("Droid Sans"),
    url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
