@use 'breakpoints';
@use 'functions';
@use 'colors';

@mixin mobile-only {
  @media (max-width: map-get(breakpoints.$breakpoints, 'mobile-only')) {
    @content;
  }
}

@mixin mobile-landscape-down {
  @media (max-width: map-get(breakpoints.$breakpoints, 'mobile-landscape-down')) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (min-width: map-get(breakpoints.$breakpoints, 'tablet-portrait-up')) and (max-width: map-get(breakpoints.$breakpoints, 'tablet-portrait-down')) {
    @content;
  }
}

@mixin tablet-portrait-down {
  @media (max-width: map-get(breakpoints.$breakpoints, 'tablet-portrait-down')) {
    @content;
  }
}

@mixin tablet-portrait-up {
  @media (min-width: map-get(breakpoints.$breakpoints, 'tablet-portrait-up')) {
    @content;
  }
}

@mixin tablet-portrait-only {
  @media (min-width: map-get(breakpoints.$breakpoints, 'tablet-portrait-up')) and (max-width: map-get(breakpoints.$breakpoints, 'tablet-portrait-down')) {
    @content;
  }
}

@mixin tablet-landscape-up {
  @media (max-width: map-get(breakpoints.$breakpoints, 'tablet-landscape-up')) {
    @content;
  }
}

@mixin tablet-landscape-down {
  @media (max-width: map-get(breakpoints.$breakpoints, 'tablet-landscape-down')) {
    @content;
  }
}

@mixin tablet-landscape-only {
  @media (min-width: map-get(breakpoints.$breakpoints, 'tablet-landscape-up')) and (max-width: map-get(breakpoints.$breakpoints, 'tablet-landscape-down')) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: map-get(breakpoints.$breakpoints, 'desktop-up')) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: map-get(breakpoints.$breakpoints, 'desktop-down')) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: map-get(breakpoints.$breakpoints, 'desktop-up')) and (max-width: map-get(breakpoints.$breakpoints, 'desktop-down')) {
    @content;
  }
}

@mixin orientation-landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin big-desktop-up {
  @media (min-width: map-get(breakpoints.$breakpoints, 'big-desktop-up')) {
    @content;
  }
}

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin crossBurger($deg) {
  position: absolute;
  transform: rotate($deg);
  top: 15px;
}

@mixin margin-auto {
  margin-left: auto;
  margin-right: auto;
}

@mixin map-colors($property, $keycolor) {
  @if (map-has-key(colors.$colors, $keycolor)) {
    #{$property}: map-get(colors.$colors, $keycolor);
  }
  // @warn "Unknown color key`#{$keycolor}` in $colors.";
}
