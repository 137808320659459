@use 'colors';
@use 'mixin';

.theme-dark {
  footer {
    @include mixin.map-colors('color', 'silver');
    a {
      &.arrow-up {
        @include mixin.map-colors('color', 'white');
        transition: color 200ms ease;
        &:hover {
          @include mixin.map-colors('color', 'logocolor');
        }
      }
    }
  }
  a {
    &.contact__link {
      @include mixin.map-colors('color', 'white');
      transition: color 200ms ease;
      &:hover {
        color: map-get($map: colors.$colors, $key: 'logocolor');
      }
    }
  }
  .burgermenu {
    @include mixin.map-colors('background-color', 'white');
    border-top: 5px solid map-get($map: colors.$colors, $key: 'carrot');
    &__item {
      &.active {
        @include mixin.map-colors('color', 'logocolor');
      }
    }
    a {
      @include mixin.map-colors('color', 'belize-hole');
      &:hover {
        @include mixin.map-colors('color', 'logocolor');
      }
    }
  }
  .nav-open {
    .togglemenu__bar {
      @include mixin.map-colors('background-color', 'black-pearl');
    }
  }

  .togglemenu__bar {
    @include mixin.map-colors('background-color', 'white');
  }

  header {
    .logo {
      background: linear-gradient(
        180deg,
        rgba(30, 39, 46, 1) 0%,
        rgba(30, 39, 46, 0.9) 90%,
        rgba(30, 39, 46, 0.5) 100%
      );
    }
  }

  .about__content {
    ul > li span {
      @include mixin.map-colors('color', 'white');
    }
    svg {
      @include mixin.map-colors('color', 'white');
    }
  }

  h1 {
    @include mixin.map-colors('color', 'white');
  }

  h2 {
    @include mixin.map-colors('color', 'white');
  }

  header h2 {
    @include mixin.map-colors('color', 'carrot');
  }

  h3 {
    @include mixin.map-colors('color', 'carrot');
    &.contact__name {
      @include mixin.map-colors('color', 'white');
    }
  }

  #skills-container .skills {
    @include mixin.map-colors('background-color', 'clouds');
    &__star {
      @include mixin.map-colors('color', 'belize-hole');
    }
    ul,
    p {
      @include mixin.map-colors('color', 'black-pearl');
    }
  }

  .customers__description {
    @include mixin.map-colors('color', 'wet-asphalt');
  }

  #contact {
    background-image: none;
  }
}
